import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { device } from "../../utils/index";

const ContainerWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  @media ${device.allMobile} {
    padding: 0 10px;
  }
`;

export default function Container(props) {
  return <ContainerWrapper>{props.children}</ContainerWrapper>;
}

Container.propTypes = {
  children: PropTypes.element,
};
