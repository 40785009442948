import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "antd";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const BreadcrumbItem = Breadcrumb.Item;
const BreadcrumbWrapper = styled.div`
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
`;
export default function BreadcrumbComponent(props) {
  return (
    <BreadcrumbWrapper>
      <Breadcrumb>
        {props.links.map((link, index) => (
          <BreadcrumbItem key={`${link.url}-${index}`}>
            <Link to={link.url}>{link.item}</Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </BreadcrumbWrapper>
  );
}

BreadcrumbComponent.propTypes = {
  links: PropTypes.array.isRequired,
};
