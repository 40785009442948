import React, { Component } from "react";
import { Form, Input, Button, Col, Row } from "antd";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import "antd/lib/form/style/css";
import "antd/lib/button/style/css";
import "antd/lib/input/style/css";
import "antd/lib/row/style/css";
import "antd/lib/col/style/css";

import { RemoveIcon, AddIcon } from "../../images/index";
import { H1 } from "../../utils/index";

const FormItem = Form.Item;

const QualificationsWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  margin-top: 25px;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
`;

const QualificationContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
  }

  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .text-area {
    margin-top: 15px;
    height: 150px;
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #171a1b;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  .remove-item {
    display: flex;
    height: 90px;
    align-items: center;
  }
`;

const QualificationsTitleWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #171a1b;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97d7d8;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
`;

let id = 1;

export default class QualificationsForm extends Component {
  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("Qualifications");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      Qualifications: keys.filter((key) => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("Qualifications");
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      Qualifications: nextKeys,
    });
  };
  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props;

    getFieldDecorator("Qualifications", {
      initialValue: [0],
    });
    const keys = getFieldValue("Qualifications");

    const formItems = keys.map((k, idx) => (
      <QualificationContent key={`Qualification-for-${idx}`}>
        <Row key={`row-for-${idx}`}>
          <Col span={22} key={`Title-for-${idx}`}>
            <FormItem label={"Title"} required={false}>
              {getFieldDecorator(`QualificationTitle[${k}]`, {
                rules: [
                  {
                    required: true,
                    message:
                      "Please input Title of Qualification or delete this field.",
                  },
                ],
              })(<Input placeholder="Title" />)}
            </FormItem>
            <FormItem label={"Body"} required={false}>
              {getFieldDecorator(`QualificationTBody[${k}]`, {
                rules: [
                  {
                    required: true,
                    message:
                      "Please input Body of Qualification or delete this field.",
                  },
                ],
              })(<Input.TextArea placeholder="Body" className="text-area" />)}
            </FormItem>
          </Col>
          {keys.length > 1 ? (
            <Col span={2} className="remove-item">
              <Button type="dashed" onClick={() => this.remove(k)}>
                <RemoveIcon />
              </Button>
            </Col>
          ) : null}
        </Row>
      </QualificationContent>
    ));

    return (
      <QualificationsWrapper>
        <QualificationsTitleWrapper>
          <H1>Qualifications</H1>
          <FormItem>
            <Button type="dashed" onClick={this.add}>
              <AddIcon />
            </Button>
          </FormItem>
        </QualificationsTitleWrapper>
        {formItems}
      </QualificationsWrapper>
    );
  }
}

QualificationsForm.propTypes = {
  form: PropTypes.object.isRequired,
};
