/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const MenuIcon = ({ color, width, height }) => {
  return (
    <svg
      version="1.1"
      height={`${height || "512"}px`}
      width={`${width || "512"}px`}
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
      css={css`
        fill: ${color || "#7b7b7b"};
        max-width: ${width || "100%"};
        max-height: ${height || "100%"};
        height: ${height || "100%"};
        width: ${width || "100%"};
      `}
    >
      <g>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m464.883 64.267h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
          fill={color || "#7b7b7b"}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m464.883 208.867h-417.766c-25.98 0-47.117 21.136-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.013-21.137-47.149-47.117-47.149z"
          fill={color || "#7b7b7b"}
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="m464.883 353.467h-417.766c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h417.766c25.98 0 47.117-21.137 47.117-47.117 0-26.012-21.137-47.149-47.117-47.149z"
          fill={color || "#7b7b7b"}
        />
      </g>
    </svg>
  );
};

export default MenuIcon;

MenuIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
