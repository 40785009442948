import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";
import { H2, convertDateToFullStringDate } from "../utils/index";

const StateCardWrapper = styled.div`
  background: #fff;
  width: 780px;
  margin-bottom: 10px;
  &:last-chiled {
    margin-bottom: 0;
  }

  a {
    display: block;
    padding: 15px;
  }

  p {
    font-family: AvenirNext;
    font-size: 16px;
    color: #9298ac;
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

export default function StateCard(props) {
  return (
    <StateCardWrapper>
      {props.to && (
        <Link to={props.to}>
          <H2>{props.title}</H2>
          <p>{convertDateToFullStringDate(props.time)}</p>
        </Link>
      )}
      {props.location && (
        <a href={props.location} target="_blank" rel="noopener noreferrer">
          <H2>{props.title}</H2>
          <p>{convertDateToFullStringDate(props.time)}</p>
        </a>
      )}
    </StateCardWrapper>
  );
}

StateCard.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  to: PropTypes.string,
  location: PropTypes.string,
};
