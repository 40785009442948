import React, { Component } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";
import "antd/lib/form/style/css";
import "antd/lib/select/style/css";

import { FormWrapper } from "../index";
import { Colors } from "../../utils/index";

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;
const MobileFromWrapper = styled.div`
  input[type="tel"] {
    width: 100%;
    height: 40px;
  }
  .country {
    line-height: 1;
  }
`;
class MobileFrom extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      addMobile,
    } = this.props;

    const user = JSON.parse(localStorage.getItem("user"));

    validateFields((err, values) => {
      if (!err && user) {
        const phone = values.phone;
        addMobile({ mobile: phone, token: user.token });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      width,
      edit,
    } = this.props;

    return (
      <FormWrapper width={edit ? width : null}>
        <MobileFromWrapper>
          <Form onSubmit={this.handleSubmit}>
            <legend>
              {edit ? "Edit" : "Enter"} Mobile Number
              <span>Please Enter Your Mobile Number</span>
            </legend>

            <FormItem label="Phone Number">
              {getFieldDecorator("phone", {
                rules: [
                  {
                    required: true,
                    message: "Please input your Mobile number!",
                  },
                ],
              })(
                <PhoneInput
                  containerStyle={{ width: "100%", height: "40px" }}
                  searchClass="search-class"
                  //defaultCountry="gb"
                  placeholder="+44 1234 567890"
                  searchStyle={{
                    margin: "0",
                    width: "100%",
                    height: "30px",
                  }}
                  enableSearchField
                  disableSearchIcon
                />
              )}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button">
                {edit ? "Send" : "Continue"}
              </SubmitButton>
            </FormItem>
          </Form>
        </MobileFromWrapper>
      </FormWrapper>
    );
  }
}

MobileFrom.propTypes = {
  form: PropTypes.object.isRequired,
  addMobile: PropTypes.func.isRequired,
  width: PropTypes.number,
  edit: PropTypes.bool,
};
export default Form.create({})(MobileFrom);
