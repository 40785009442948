import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Layout from "antd/lib/layout";
import styled from "@emotion/styled";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { Header, Footer, Container, MainFooter } from "../index";
import { device, ProfilePic } from "../../utils/index";
import {
  LogoConsultant,
  LogoHospital,
  LogoLab,
  LogoPatient,
  ReportIcon,
  HouseIcon,
  SentQuotationsIcon,
  NewQuotationsIcon,
  BookedQuotationsIcon,
  MenuIcon,
} from "../../images/index";

const { Content } = Layout;

const ConsultantLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    color: #9298ac;
    span {
      margin-left: 10px;
    }
    & + a {
      margin-left: 20px;
    }
  }
`;

const BaseLayoutWrapper = styled.div`
  .base-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    width: 210px;
    position: relative;
    top: -8px;
    @media ${device.allMobile} {
      width: 50%;
    }
    img {
      width: 100%;
    }
  }
`;
const SvgWrapper = styled.span`
  max-width: ${(props) => (props.width ? props.width : "24px")};
  min-width: ${(props) => (props.width ? props.width : "24px")};
  height: ${(props) => (props.height ? props.height : "24px")};
  display: inline-block;
  vertical-align: middle;
  svg {
    display: inline-block;
  }
`;

const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: #fff;

  ul {
    padding: 0;
    list-style: none;
    width: 100%;
    li {
      width: 100%;
      padding: 0 15px;
      .close-button {
        color: #7b7b7b;
        font-weight: bold;
        background: none;
        border: none;
        font-size: 24px;
      }
      a {
        width: 100%;
        padding: 0;
        display: block;
        color: #97d7d8;
        margin-bottom: 15px;
        font-size: 16px;
        font-weight: bold;
      }
      &.logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 25px;

        a {
          width: 50%;
          display: inline-block;
          border-bottom: none;
          margin-bottom: 0;
          padding: 10px 0;
        }
      }
      &.profile {
        margin-bottom: 25px;
        a {
          display: block;
          width: 100%;
          display: flex;
          align-items: center;
          border-bottom: none;
          margin-bottom: 0;
        }
        .full-name {
          font-family: AvenirNext;
          font-size: 16px;
          color: #9298ac;
          font-weight: bold;
          margin-left: 15px;
        }
      }
      &.logout {
        a {
          color: red;
        }
      }
    }
  }
`;

const ContentWrapper = styled.div`
  min-height: ${(props) => (props.mainLayout ? "calc(100vh - 412px)" : `1px`)};
`;
class BaseLayout extends Component {
  state = {
    showModal: false,
  };
  handelShowModal = () => {
    this.setState({
      showModal: true,
    });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const userType = localStorage.getItem("userType");
    const user = JSON.parse(localStorage.getItem("user"));

    const LogoRender = () => {
      const userType = localStorage.getItem("userType");
      switch (userType) {
        case "consultant":
          return LogoConsultant;
        case "laboratory":
          return LogoLab;
        case "hospital":
          return LogoHospital;
        case "patient":
          return LogoPatient;
        default:
          return LogoPatient;
      }
    };

    const menuLinks = () => {
      return (
        <>
          {(userType === "consultant" ||
            userType === "laboratory" ||
            userType === "hospital") && (
            <ConsultantLinksWrapper>
              <Link to="/">
                {userType === "hospital" ? (
                  <NewQuotationsIcon
                    color={
                      !SentQuotations &&
                      !BookedRequests &&
                      !ReportPage &&
                      !HospitalRequests
                        ? "#97d7d8"
                        : "#9298ac"
                    }
                  />
                ) : (
                  <HouseIcon
                    color={
                      !SentQuotations &&
                      !BookedRequests &&
                      !ReportPage &&
                      !HospitalRequests
                        ? "#97d7d8"
                        : "#9298ac"
                    }
                  />
                )}

                <span
                  style={
                    !SentQuotations &&
                    !BookedRequests &&
                    !ReportPage &&
                    !HospitalRequests
                      ? { color: "#97d7d8" }
                      : { color: "#9298ac" }
                  }
                >
                  {userType === "consultant"
                    ? "Consultations"
                    : userType === "hospital"
                    ? "New Requests"
                    : "Requests"}
                </span>
              </Link>
              {userType === "hospital" && (
                <>
                  <Link to="/hospital-requests">
                    <SentQuotationsIcon
                      color={HospitalRequests ? "#97d7d8" : "#9298ac"}
                    />
                    <span
                      style={
                        HospitalRequests
                          ? { color: "#97d7d8" }
                          : { color: "#9298ac" }
                      }
                    >
                      Hospital Requests
                    </span>
                  </Link>
                  <Link to="/sent-quotations">
                    <SentQuotationsIcon
                      color={SentQuotations ? "#97d7d8" : "#9298ac"}
                    />
                    <span
                      style={
                        SentQuotations
                          ? { color: "#97d7d8" }
                          : { color: "#9298ac" }
                      }
                    >
                      Sent Quotations
                    </span>
                  </Link>
                  <Link to="/booked-requests">
                    <BookedQuotationsIcon
                      color={BookedRequests ? "#97d7d8" : "#9298ac"}
                    />
                    <span
                      style={
                        BookedRequests
                          ? { color: "#97d7d8" }
                          : { color: "#9298ac" }
                      }
                    >
                      Booked Requests
                    </span>
                  </Link>
                </>
              )}
              {userType !== "hospital" && (
                <Link to="/reports">
                  <ReportIcon color={ReportPage ? "#97d7d8" : "#9298ac"} />
                  <span
                    style={
                      ReportPage ? { color: "#97d7d8" } : { color: "#9298ac" }
                    }
                  >
                    Reports
                  </span>
                </Link>
              )}
            </ConsultantLinksWrapper>
          )}
        </>
      );
    };
    const { showModal } = this.state;
    const {
      navigation,
      children,
      footer,
      ReportPage,
      HomePage,
      SentQuotations,
      BookedRequests,
      mainLayout,
      HospitalRequests,
    } = this.props;

    return (
      <>
        <BaseLayoutWrapper>
          <Header>
            <Container>
              <div className="base-header-wrapper">
                <Link to="/" className="logo">
                  <picture>
                    <img src={LogoRender()} alt="Holoden Khight Logo" />
                  </picture>
                </Link>
                {user && <BrowserView>{menuLinks()}</BrowserView>}
                <BrowserView>{navigation}</BrowserView>
                {user && (
                  <MobileView>
                    <SvgWrapper onClick={() => this.handelShowModal()}>
                      <MenuIcon />
                    </SvgWrapper>
                  </MobileView>
                )}
              </div>
            </Container>
          </Header>
          <Content
            style={{
              padding: `${isMobile || !mainLayout ? "0" : "3em 10em"}`,
              margin: 0,
              minHeight: 280,
              backgroundColor: "#f5f5f5",
            }}
          >
            <ContentWrapper mainLayout={mainLayout}>{children}</ContentWrapper>
          </Content>
          {footer === "main" ? (
            <MainFooter />
          ) : (
            <Footer>
              <p>Phantasm &copy; 2022</p>
            </Footer>
          )}
        </BaseLayoutWrapper>
        {showModal && (
          <MobileMenu>
            <ul>
              <li className="logo">
                <Link to="/">
                  <picture>
                    <img src={LogoRender()} alt="Holoden Khight Logo" />
                  </picture>
                </Link>
                <button
                  className="close-button"
                  onClick={() => this.handelCloseModal()}
                >
                  &#10006;
                </button>
              </li>
              <li className="profile">
                <Link to="/profile">
                  <ProfilePic
                    user={user}
                    width="50"
                    height="50"
                    style={{ borderRadius: "50%" }}
                  />{" "}
                  <span className="full-name">{user.name}</span>
                </Link>
              </li>
              {(userType === "consultant" ||
                userType === "laboratory" ||
                userType === "hospital") &&
                user && (
                  <>
                    <li>
                      <Link to="/">
                        <span>
                          {userType === "consultant"
                            ? "Consultations"
                            : userType === "hospital"
                            ? "New Requests"
                            : "Requests"}
                        </span>
                      </Link>
                    </li>
                    {userType === "consultant" && (
                      <>
                        <li>
                          <Link to="/my-activity">
                            <span>Activity</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/my-appointment">
                            <span>Appointments</span>
                          </Link>
                        </li>
                      </>
                    )}
                    {userType === "hospital" && (
                      <>
                        <li>
                          <Link to="/sent-quotations">
                            <span>Sent Quotations</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/booked-requests">
                            <span>Booked Requests</span>
                          </Link>
                        </li>
                      </>
                    )}
                    {userType !== "hospital" && (
                      <li>
                        <Link to="/reports">
                          <span>Reports</span>
                        </Link>
                      </li>
                    )}
                    <li className="logout">
                      {" "}
                      <Link
                        onClick={() => {
                          localStorage.removeItem("user");
                          localStorage.removeItem("userType");
                          localStorage.removeItem("RequestConsultationSteps");
                        }}
                        to="/login"
                      >
                        <span className="link-text">Logout</span>
                      </Link>
                    </li>
                  </>
                )}
            </ul>
          </MobileMenu>
        )}
      </>
    );
  }
}

export default BaseLayout;

BaseLayout.propTypes = {
  navigation: PropTypes.object,
  children: PropTypes.object,
  alert: PropTypes.object,
  footer: PropTypes.string,
  ReportPage: PropTypes.bool,
  HomePage: PropTypes.bool,
  SentQuotations: PropTypes.bool,
  BookedRequests: PropTypes.bool,
  mainLayout: PropTypes.bool,
};
