/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const CheckIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || "30"}px`}
      width={`${width || "30"}px`}
      viewBox="0 0 30 30"
      css={css`
        fill: ${color || "#97d7d8"};
        max-width: ${width || "30"}px;
        max-height: ${height || "30"}px;
        height: ${height || "30"}px;
        width: ${width || "30"}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fillOpacity: 1,
          }}
          d="M 15 0 C 6.757812 0 0 6.757812 0 15 C 0 23.242188 6.757812 30 15 30 C 23.242188 30 30 23.242188 30 15 C 30 6.757812 23.242188 0 15 0 Z M 15 0 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fillOpacity: 1,
          }}
          d="M 30 15 C 30 23.242188 23.242188 30 15 30 L 15 0 C 23.242188 0 30 6.757812 30 15 Z M 30 15 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fillOpacity: 1,
            fill: "rgb(100%,100%,100%)",
          }}
          d="M 23.542969 12.453125 L 13.242188 22.75 L 5.839844 15.367188 L 8.339844 12.871094 L 13.242188 17.792969 L 21.0625 9.972656 Z M 23.542969 12.453125 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fillOpacity: 1,
            fill: "rgb(100%,100%,100%)",
          }}
          d="M 23.542969 12.453125 L 15 20.992188 L 15 16.035156 L 21.0625 9.972656 Z M 23.542969 12.453125 "
        />
      </g>
    </svg>
  );
};

export default CheckIcon;

CheckIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
