import React, { Component } from "react";
import { Form, Select, Button, Col, Row, TimePicker, Icon, Input } from "antd";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import moment from "moment";

import "antd/lib/form/style/css";
import "antd/lib/button/style/css";
import "antd/lib/input/style/css";
import "antd/lib/row/style/css";
import "antd/lib/col/style/css";
import "antd/lib/time-picker/style/css";

import { RemoveIcon, AddIcon } from "../../images/index";
import { H1 } from "../../utils/index";

const FormItem = Form.Item;
const { Option } = Select;

const AppointmentTimeWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  margin-top: 25px;
  .block {
    opacity: 0.18;
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: transparent;
    }
  }
  .appointment-action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      width: 48%;
      background: none;
      border: none;
      cursor: pointer;
      color: #171a1b;
      font-family: AvenirNext;
      font-size: 24px;
      display: flex;
      align-items: center;
      span {
        width: 50px;
        height: 50px;
        background-color: #171a1b;
        border: 1px solid #171a1b;
        border-radius: 50%;
        margin-right: 18px;
        color: #fff;
        padding: 9px;
        display: inline-block;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-weight: 900;
          font-size: 30px;
          margin: 0;
          line-height: 50px;
          vertical-align: middle;
          padding-top: 3px;
        }
      }
      & + button {
        color: #97d7d8;
        span {
          background-color: #97d7d8;
          border: 1px solid #97d7d8;
          p {
            font-size: 55px;
            padding-top: 0;
          }
        }
      }
    }
  }
  .appointment-error {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;

    color: #ec0707;
  }
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #97d7d8;
    line-height: 24px;
    margin-right: 10px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
  .edit-close {
    margin-top: 10px;
    pointer-events: none;
    * {
      border: none;
    }
    .remove-item,
    .anticon-clock-circle,
    .ant-select-arrow {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-time-picker {
      margin-left: -13px;
    }
  }
`;

const AppointmentTimeContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #171a1b;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  .remove-item {
    display: flex;
    height: 90px;
    align-items: center;
  }
`;

const AppointmentTimeTitleWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h1 {
    color: #171a1b;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #171a1b;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97d7d8;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
`;

let id = 1;

export default class AppointmentTimeForm extends Component {
  state = {
    selctedTime: [],
    selectedKey: null,
    createNewSchedule: false,
  };
  remove = (k) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("AppointmentTime");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      AppointmentTime: keys.filter((key) => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue("AppointmentTime");

    const nextKeys = keys.concat(keys.length || id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      AppointmentTime: nextKeys,
    });
  };

  onChange = (time, k) => {
    const { selctedTime } = this.state;
    const newSelctedTime = [...selctedTime];
    newSelctedTime[k] = time.format("X");
    this.setState({ selctedTime: newSelctedTime });
  };
  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
      schedules,
      updateFromMoreThanTwoWeek,
    } = this.props;
    let initial = null;
    if (schedules && schedules.length > 0) {
      initial = [];
      schedules.map((s, index) => initial.push(index));
    }
    const { selctedTime, createNewSchedule } = this.state;
    getFieldDecorator("AppointmentTime", {
      initialValue: initial || [0],
    });
    const keys = getFieldValue("AppointmentTime");

    const AppointmentItems = keys.map((k) => (
      <AppointmentTimeContent
        key={
          schedules && schedules.length > k
            ? JSON.stringify(schedules[k])
            : `AppointmentTime-for-${k}`
        }
        className={
          editFormIsOpen || createNewSchedule ? "edit-open" : "edit-close"
        }
      >
        <Row key={`row-for-${k}`}>
          <Col span={8} key={`Title-for-${k}`}>
            <div style={{ display: "none" }}>
              <FormItem required={false}>
                {getFieldDecorator(`AppointmentTimeId[${k}]`, {
                  initialValue:
                    schedules && schedules.length > k && schedules[k].id,
                })(<Input />)}
              </FormItem>
            </div>

            <FormItem label={"Day"} required={true}>
              {getFieldDecorator(`AppointmentTimeDay[${k}]`, {
                initialValue:
                  schedules && schedules.length > k && schedules[k].day,
                rules: [
                  {
                    required: true,
                    message: "Please choose a day.",
                  },
                ],
              })(
                <Select placeholder="Day">
                  <Option value={"Sunday"}>Sunday</Option>
                  <Option value={"Monday"}>Monday</Option>
                  <Option value={"Tuesday"}>Tuesday</Option>
                  <Option value={"Wednesday"}>Wednesday</Option>
                  <Option value={"Thursday"}>Thursday</Option>
                  <Option value={"Friday"}>Friday</Option>
                  <Option value={"Saturday"}>Saturday</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={7} key={`From-for-${k}`}>
            <FormItem label={"From"} required={true}>
              {getFieldDecorator(`AppointmentTimeFrom[${k}]`, {
                validateTrigger: ["onChange"],
                initialValue:
                  schedules &&
                  schedules.length > k &&
                  moment.unix(schedules[k].from_time, "hh:mm A"),
                rules: [
                  {
                    required: true,
                    message:
                      "Please choose the hour you will be available from.",
                  },
                ],
              })(
                <TimePicker
                  minuteStep={30}
                  use12Hours
                  format="h:mm a"
                  onChange={(e) => {
                    this.onChange(e, k);
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={7} key={`To-for-${k}`}>
            <FormItem label={"To"} required={true}>
              {getFieldDecorator(`AppointmentTimeTo[${k}]`, {
                validateTrigger: ["onChange"],
                initialValue:
                  selctedTime && selctedTime[k]
                    ? moment(
                        moment.unix(selctedTime[k]).add(30, "minutes"),
                        "h:mm a"
                      )
                    : schedules &&
                      schedules.length > k &&
                      moment.unix(schedules[k].to_time, "hh:mm A"),
                rules: [
                  {
                    required: true,
                    message:
                      "Please choose the hour till when will you be available.",
                  },
                ],
              })(<TimePicker minuteStep={30} use12Hours format="h:mm a" />)}
            </FormItem>
          </Col>
          {keys.length > 1 ? (
            <Col span={2} className="remove-item">
              <Button type="dashed" onClick={() => this.remove(k)}>
                <RemoveIcon />
              </Button>
            </Col>
          ) : null}
        </Row>
      </AppointmentTimeContent>
    ));

    return (
      <AppointmentTimeWrapper>
        <AppointmentTimeTitleWrapper>
          <H1>Appointment Availability</H1>
          {(!updateFromMoreThanTwoWeek || createNewSchedule) && (
            <>
              {handleEditFormToggle ? (
                editFormIsOpen || createNewSchedule ? (
                  <>
                    <FormItem>
                      <Button type="dashed" onClick={this.add}>
                        <AddIcon />
                      </Button>
                    </FormItem>
                    <div>
                      <FormItem>
                        <button htmltype="submit" className="save">
                          Save
                        </button>
                      </FormItem>
                      <FormItem>
                        <button
                          htmltype="button"
                          className="close"
                          onClick={() => {
                            handleCancel();
                            this.setState({ createNewSchedule: false });
                          }}
                        >
                          &times;
                        </button>
                      </FormItem>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="ant-dropdown-link"
                      onClick={(ev) => {
                        ev.preventDefault();
                        handleEditFormToggle();
                      }}
                    >
                      <Icon type="edit" theme="filled" />
                    </button>
                  </>
                )
              ) : (
                <>
                  <FormItem>
                    <Button type="dashed" onClick={this.add}>
                      <AddIcon />
                    </Button>
                  </FormItem>
                </>
              )}
            </>
          )}
        </AppointmentTimeTitleWrapper>
        <div
          className={
            updateFromMoreThanTwoWeek && !createNewSchedule ? "block" : ""
          }
        >
          {(editFormIsOpen || (schedules && schedules.length > 0)) &&
            AppointmentItems}
        </div>
        {updateFromMoreThanTwoWeek && !createNewSchedule && (
          <>
            <p className="appointment-error">
              Your schedule has expired, please confirm the renewal of your old
              schedule or create a new one.
            </p>
            <div className="appointment-action">
              <button htmltype="submit">
                <span>
                  <p>&#x21bb;</p>
                </span>
                Repeat old schedule
              </button>
              <button
                htmltype="button"
                onClick={() => {
                  this.setState({ createNewSchedule: true });
                }}
              >
                <span>
                  <p>&#x2b;</p>
                </span>
                Create new
              </button>
            </div>
          </>
        )}
      </AppointmentTimeWrapper>
    );
  }
}

AppointmentTimeForm.propTypes = {
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
  schedules: PropTypes.array,
};
