import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { BaseLayout } from "../index";
import { Dropdown, Icon, Menu } from "antd";
import { ProfilePic } from "../../utils/index";

import "antd/lib/dropdown/style/css";
import "antd/lib/icon/style/css";
import "antd/lib/menu/style/css";

const LayoutWrapper = styled.div`
  background-color: #f5f5f5;
  min-height: 100vh;
  .layout-navigation {
    line-height: 64px;
    list-style: none;
    padding: 0;

    li:first-of-type {
      display: flex;
    }
    img,
    .navigation .ant-dropdown-link {
      width: 48px;
      height: 48px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      .anticon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #97d7d8;
      }
    }
    .navigation .ant-dropdown-link {
      margin-right: 20px;
    }
    .ant-dropdown-link {
      border-radius: 50%;
      background-color: rgb(247, 251, 253);
      padding: 10px;
      &:hover {
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
      }

      .anticon {
        color: rgb(99, 99, 99);
      }
      &.profile-dropdown {
        position: relative;
        border-radius: 50%;
        background-color: rgb(238, 238, 238);
        margin-left: -5px;
        border: 2px solid rgb(255, 255, 255);
        padding: 0;
        .anticon {
          color: rgb(99, 99, 99);
          margin: 2px;
        }
      }
    }
  }
`;

class MainLayout extends PureComponent {
  render() {
    const { children, footer, ReportPage, logout } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));

    console.log(logout);
    const accountMenu = (
      <Menu>
        <Menu.Item>
          <Link to="/profile">
            <span className="link-text">Account</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            onClick={() => {
              if (logout) {
                return logout();
              } else {
                localStorage.removeItem("user");
                localStorage.removeItem("userType");
                localStorage.removeItem("RequestConsultationSteps");
                return;
              }
            }}
            to="/login"
          >
            <span className="link-text">Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <LayoutWrapper>
        <BaseLayout
          {...this.props}
          mainLayout={true}
          className="layout"
          footer={footer ? footer : null}
          ReportPage={ReportPage}
          navigation={
            <ul className="layout-navigation" mode="horizontal">
              {user && (
                <li key="2">
                  {/* <Dropdown overlay={menu}>
                    <div className="navigation">
                      <Link className="ant-dropdown-link" to="#!">
                        <Icon type="bell" theme="filled" />
                      </Link>
                    </div>
                  </Dropdown> */}

                  <Dropdown overlay={accountMenu}>
                    <div>
                      <ProfilePic
                        user={user}
                        width="36"
                        height="36"
                        style={{ borderRadius: "50%" }}
                      />
                      <a
                        className="ant-dropdown-link profile-dropdown"
                        href="#!"
                      >
                        <Icon type="down" />
                      </a>
                    </div>
                  </Dropdown>
                </li>
              )}
            </ul>
          }
          children={children}
        />
      </LayoutWrapper>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.object,
  footer: PropTypes.string,
  ReportPage: PropTypes.bool,
  logout: PropTypes.func.isRequired,
};

export default MainLayout;
