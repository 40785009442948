import React, { Component } from "react";
import { Form, Input, Modal } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import "antd/lib/form/style/css";
import "antd/lib/modal/style/css";

import { FormWrapper, MobileForm } from "../index";
import { Colors } from "../../utils/index";

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const DidntReceive = styled.p`
  font-family: SFProText;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  color: ${Colors.black};
  margin 20px auto 8px;
`;
const Resend = styled.div`
  text-align: center;
  button {
    background: none;
    border: none;
    font-family: SFProText;
    font-size: 17px;
    font-weight: 600;
    color: ${Colors.black};
  }
`;

class VerifyMobile extends Component {
  state = { visible: false };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      verify,
    } = this.props;

    const user = JSON.parse(localStorage.getItem("user"));

    validateFields((err, values) => {
      if (!err && user) {
        verify({ code: values.code, token: user.token });
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
      phone,
      addMobile,
      resendCode,
    } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    return (
      <>
        <FormWrapper>
          <>
            <Form onSubmit={this.handleSubmit}>
              <legend>
                Verify your mobile Number
                <span>
                  We’ve sent you a verification code via SMS to the number
                  {` ${phone}`}
                  <button href="#!" onClick={this.showModal}>
                    Edit
                  </button>
                </span>
              </legend>

              <FormItem label="verification code">
                {getFieldDecorator("code", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your verification code!",
                      len: 6,
                    },
                  ],
                })(
                  <Input type="number" placeholder="Enter verification code" />
                )}
              </FormItem>
              <FormItem>
                <SubmitButton className="submit-button">Verify</SubmitButton>
              </FormItem>
            </Form>
            <DidntReceive>Didn’t receive code?</DidntReceive>
            <Resend>
              <button onClick={() => resendCode(user.token)}>Resend</button>
            </Resend>
          </>
        </FormWrapper>
        <Modal
          visible={this.state.visible}
          centered={true}
          onCancel={this.handleCancel}
          footer={[
            <button
              key="ok"
              className="visually-hidden"
              onClick={this.handleOk}
            >
              send
            </button>,
          ]}
        >
          <>
            <MobileForm
              width={460}
              edit={true}
              submite={this.handleOk}
              addMobile={addMobile}
            />
          </>
        </Modal>
      </>
    );
  }
}

VerifyMobile.propTypes = {
  form: PropTypes.object.isRequired,
  phone: PropTypes.string.isRequired,
  addMobile: PropTypes.func.isRequired,
  resendCode: PropTypes.func.isRequired,
  verify: PropTypes.func.isRequired,
};

export default Form.create({})(VerifyMobile);
