import React from "react";
import PropTypes from "prop-types";
import { MalePic, FemalePic } from "../images/index";

export default function ProfilePic(props) {
  const check =
    props.user &&
    props.user.picture &&
    (props.user.picture.startsWith("https") ||
      props.user.picture.startsWith("http"));

  return (
    <>
      <img
        {...props}
        alt={props.user && props.user.name}
        src={
          props.user && props.user.picture
            ? props.user.picture && !check
              ? `https://api.hk.phantasm.biz/storage/${props.user.picture}`
              : props.user.picture
            : props.user
            ? props.user.gender === "male"
              ? MalePic
              : props.user.gender === "female"
              ? FemalePic
              : MalePic
            : MalePic
        }
      />
    </>
  );
}

ProfilePic.propTypes = {
  user: PropTypes.object,
};
