/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const HouseIcon = ({ color, width, height }) => {
  return (
    <svg
      css={css`
        fill: ${color || "#97d7d8"};
        max-width: ${width || "24"}px;
        max-height: ${height || "24"}px;
        height: ${height || "24"}px;
        width: ${width || "24"}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: "#none",
            fillRule: "nonzero",
            fillOpacity: 1,
          }}
          d="M 23.78125 11.09375 L 14.453125 1.765625 C 13.101562 0.414062 10.898438 0.414062 9.546875 1.765625 L 0.21875 11.09375 C -0.0742188 11.386719 -0.0742188 11.859375 0.21875 12.152344 C 0.511719 12.445312 0.988281 12.445312 1.28125 12.152344 L 1.875 11.558594 L 1.875 21.09375 C 1.875 22.285156 2.839844 23.25 4.03125 23.25 L 7.78125 23.25 C 8.039062 23.25 8.25 23.039062 8.25 22.78125 L 8.25 15.84375 C 8.25 14.546875 9.300781 13.5 10.59375 13.5 L 13.40625 13.5 C 14.699219 13.5 15.75 14.546875 15.75 15.84375 L 15.75 22.78125 C 15.75 23.039062 15.960938 23.25 16.21875 23.25 L 19.96875 23.25 C 21.160156 23.25 22.125 22.285156 22.125 21.09375 L 22.125 11.558594 L 22.71875 12.152344 C 22.867188 12.300781 23.058594 12.375 23.25 12.375 C 23.441406 12.375 23.632812 12.300781 23.78125 12.152344 C 24.074219 11.859375 24.074219 11.386719 23.78125 11.09375 Z M 23.78125 11.09375 "
        />
      </g>
    </svg>
  );
};

export default HouseIcon;

HouseIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
