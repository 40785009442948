/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const AddIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || "24"}px`}
      width={`${width || "24"}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || "#97d7d8"};
        max-width: ${width || "24"}px;
        max-height: ${height || "24"}px;
        height: ${height || "24"}px;
        width: ${width || "24"}px;
      `}
    >
      <g id="surface1">
        <path d="M 20 0 C 8.972656 0 0 8.972656 0 20 C 0 31.027344 8.972656 40 20 40 C 31.027344 40 40 31.027344 40 20 C 40 8.972656 31.027344 0 20 0 Z M 28.75 21.667969 L 21.667969 21.667969 L 21.667969 28.75 C 21.667969 29.671875 20.921875 30.417969 20 30.417969 C 19.078125 30.417969 18.332031 29.671875 18.332031 28.75 L 18.332031 21.667969 L 11.25 21.667969 C 10.328125 21.667969 9.582031 20.921875 9.582031 20 C 9.582031 19.078125 10.328125 18.332031 11.25 18.332031 L 18.332031 18.332031 L 18.332031 11.25 C 18.332031 10.328125 19.078125 9.582031 20 9.582031 C 20.921875 9.582031 21.667969 10.328125 21.667969 11.25 L 21.667969 18.332031 L 28.75 18.332031 C 29.671875 18.332031 30.417969 19.078125 30.417969 20 C 30.417969 20.921875 29.671875 21.667969 28.75 21.667969 Z M 28.75 21.667969 " />
      </g>
    </svg>
  );
};

export default AddIcon;

AddIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
