import { css } from '@emotion/core';
import { Colors } from './Theme';

const Title = css`
  font-size: 30px;
  text-align: center;
  font-weight: 900;
  color: ${Colors.primaryColor};
`;

export default Title;
