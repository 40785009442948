/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const ConsultantinIcon = ({ width, height }) => {
  return (
    <svg
      height={`${height || "120"}px`}
      width={`${width || "131"}px`}
      viewBox="0 0 131 120"
      css={css`
        max-width: ${width || "131"}px;
        max-height: ${height || "120"}px;
        height: ${height || "120"}px;
        width: ${width || "131"}px;
      `}
    >
      <g fill="none">
        <path
          fill="#FFF"
          d="M107.173 72.263c5.542 0 8.281 5.277 8.281 9.85 0 11.716-16.566 22.51-16.566 22.51S82.32 93.903 82.32 82.113c0-4.57 2.233-9.856 8.284-9.856 4.576.001 8.285 3.711 8.284 8.287 0-2.197.873-4.304 2.427-5.857 1.553-1.554 3.66-2.426 5.858-2.424z"
        />
        <path
          fill="#2B90BE"
          d="M97.52 119.718c-9.359-.415-18.04-4.996-23.664-12.487-5.624-7.49-7.601-17.105-5.388-26.207.395-1.635.923-3.234 1.578-4.783 6.109-14.444 21.962-22.141 37.09-18.008 15.129 4.133 24.866 18.821 22.782 34.365-2.084 15.544-15.347 27.149-31.03 27.15-.46 0-.914-.008-1.369-.03zm17.934-37.604c0-4.574-2.739-9.851-8.281-9.851-2.197-.002-4.305.87-5.858 2.424-1.554 1.553-2.427 3.66-2.427 5.857 0-4.576-3.708-8.286-8.284-8.287-6.05 0-8.284 5.286-8.284 9.857 0 11.788 16.568 22.51 16.568 22.51s16.566-10.795 16.566-22.51z"
        />
        <path
          fill="#97d7d8"
          d="M97.52 119.718H7.366c-2.86 0-7.116-3.725-7.116-6.585v-12.197c0-17.24 21.276-21.078 34.941-25.099l.003.009c0 4.82 5.72 14.823 17.156 14.823 8.025 0 13.72-5.11 16.114-9.645h.003c-2.213 9.102-.236 18.717 5.388 26.207 5.624 7.491 14.305 12.072 23.663 12.487zM76.523 21.4c0 .6-.115 2.66-.31 5.07 0-8.323-9.566-13.578-9.566-13.578s-2.285 8.705-18.91 16.224c-17.226 7.79-20.897-4.41-21.072-5.031 0-.009-.003-.017-.005-.026-.15-.74-.257-1.31-.302-1.62C25.626 17.447 29.297.127 51.908.127 73.07.127 76.523 15.86 76.523 21.4z"
        />
        <path
          fill="#D4DAEB"
          d="M70.046 76.24c-.655 1.55-1.183 3.15-1.578 4.784h-.003c-2.394 4.535-8.09 9.645-16.114 9.645-11.435 0-17.156-10.004-17.156-14.823l-.003-.009c.862-.254 1.689-.509 2.482-.765l3.44-7.412.446-.466c2.676 1.692 6.186 2.813 10.791 2.813 4.597 0 8.1-1.09 10.777-2.736l.02.033 3.499 7.87c1.084.367 2.222.717 3.4 1.067z"
        />
        <path
          fill="#D4DAEB"
          d="M74.761 36.322c3.061-3.484 7.158-.39 6.023 4.424-1.423 6.029-5.3 10.806-9.05 10.735-.014 0-.245 10.617-8.586 15.778l-.02.012c-2.676 1.646-6.18 2.736-10.777 2.736-4.605 0-8.115-1.121-10.791-2.813-8.206-5.18-8.578-15.713-8.595-15.713-3.747.071-7.627-4.706-9.05-10.735-1.138-4.814 2.962-7.649 6.023-4.427-.77-1.014-2.536-8.615-3.273-12.234.175.62 3.846 12.821 21.072 5.031 16.625-7.519 18.91-16.224 18.91-16.224s9.566 5.255 9.566 13.579c-.322 4.046-.867 9.083-1.452 9.85z"
        />
      </g>
    </svg>
  );
};

export default ConsultantinIcon;

ConsultantinIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
