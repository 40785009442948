import React from "react";
import { Pagination, Icon } from "antd";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Colors } from "./Theme";
import { Link } from "react-router-dom";

const PaginationWrapper = styled.div`
  display: block;
  margin: auto;
  text-align: right;
  margin-top: 30px;
  ul {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      text-align: center;
      line-height: 36px;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      background: ${Colors.white};
      a {
        color: ${Colors.primaryColor};
      }
      &:focus {
        outline: none;
      }
      .ant-pagination-item-container {
        .anticon-double-right,
        .anticon-double-left {
          display: none;
        }
      }
      &:hover {
        background: ${Colors.secondaryColor};
        a {
          color: ${Colors.white};
        }
      }
      &:first-of-type {
        margin-left: 0;
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 10px;
      }
      & + li {
        margin-left: 5px;
      }
      &.ant-pagination-jump-next,
      &.ant-pagination-jump-prev {
        border-radius: 0;
        border: 0;
      }
      &.ant-pagination-item-active {
        background: ${Colors.secondaryColor};
        a {
          color: ${Colors.white};
        }
      }
    }
  }
`;

function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return (
      <Link to={`/${current - 1}`}>
        <Icon type="caret-left" />
      </Link>
    );
  }
  if (type === "next") {
    return (
      <Link to={`/${current + 1}`}>
        <Icon type="caret-right" />
      </Link>
    );
  }
  return originalElement;
}

export default function PaginationComponent(props) {
  const { setPage, pageSize, total } = props;
  function onChange(pageNumber) {
    setPage(pageNumber);
  }
  return (
    <PaginationWrapper className="pagination">
      <Pagination
        onChange={onChange}
        showTitle={false}
        pageSize={pageSize}
        total={total}
        itemRender={itemRender()}
        {...props}
      />
    </PaginationWrapper>
  );
}

PaginationComponent.propTypes = {
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};
