import React, { Component } from "react";
import { Form, Select, Input, Col, Row, Icon } from "antd";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import "antd/lib/form/style/css";
import "antd/lib/button/style/css";
import "antd/lib/input/style/css";
import "antd/lib/row/style/css";
import "antd/lib/col/style/css";

import { H1 } from "../../utils/index";

const FormItem = Form.Item;
const { Option } = Select;

const AboutMeWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  margin-top: 25px;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
`;

const AboutUserWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h1 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #171a1b;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97d7d8;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #97d7d8;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
`;
const AboutMeContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #171a1b;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  .remove-item {
    display: flex;
    height: 90px;
    align-items: center;
  }
`;

export default class AboutMeForm extends Component {
  render() {
    const {
      form: { getFieldDecorator },
      consultationTypes,
      patientTypes,
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
      user,
    } = this.props;

    return (
      <AboutMeWrapper>
        <AboutUserWrapper>
          <H1>About Me</H1>
          {handleEditFormToggle ? (
            editFormIsOpen ? (
              <div>
                <FormItem>
                  <button htmltype="submit" className="save">
                    Save
                  </button>
                </FormItem>
                <FormItem>
                  <button
                    htmltype="button"
                    className="close"
                    onClick={handleCancel}
                  >
                    &times;
                  </button>
                </FormItem>
              </div>
            ) : (
              <button
                className="ant-dropdown-link"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleEditFormToggle();
                }}
              >
                <Icon type="edit" theme="filled" />
              </button>
            )
          ) : (
            <></>
          )}
        </AboutUserWrapper>
        <AboutMeContent>
          <Row>
            <Col span={12}>
              <FormItem label="Patient type">
                {getFieldDecorator("patientTypeId", {
                  initialValue: user && user.patientTypeId,
                })(
                  <Select
                    placeholder="patient-type"
                    disabled={!editFormIsOpen && true}
                  >
                    {patientTypes &&
                      patientTypes.map((type) => (
                        <Option key={`patientTypes-${type.id}`} value={type.id}>
                          {type.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Speciality">
                {getFieldDecorator("consultationTypes", {
                  initialValue: user && user.consultationTypes[0],
                })(
                  <Select
                    placeholder="speciality"
                    disabled={!editFormIsOpen && true}
                  >
                    {consultationTypes &&
                      consultationTypes.map((type) => (
                        <Option
                          key={`consultationTypes-${type.id}`}
                          value={type.id}
                        >
                          {type.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem label="GMC number">
                {getFieldDecorator("gmcNumber", {
                  type: "number",
                  initialValue: user && user.gmcNumber,
                })(<Input disabled={!editFormIsOpen && true} />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Current employer">
                {getFieldDecorator("currentEmployer", {
                  type: "text",
                  initialValue: user && user.currentEmployer,
                })(
                  <Input
                    placeholder="Current employer"
                    disabled={!editFormIsOpen && true}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem label="Years of experience as a consultant">
                {getFieldDecorator("yearsOfExperienceAsConsultant", {
                  type: "number",
                  initialValue: user && user.yearsOfExperienceAsConsultant,
                })(<Input disabled={!editFormIsOpen && true} />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Job title">
                {getFieldDecorator("jobTitle", {
                  initialValue: user && user.jobTitle,
                })(
                  <Input
                    placeholder="Job title"
                    disabled={!editFormIsOpen && true}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </AboutMeContent>
      </AboutMeWrapper>
    );
  }
}

AboutMeForm.propTypes = {
  form: PropTypes.object.isRequired,
  consultationTypes: PropTypes.array,
  patientTypes: PropTypes.array,
  user: PropTypes.object,
  editFormIsOpen: PropTypes.bool,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
};
