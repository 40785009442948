/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const CamraIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      enableBackground="new 0 0 488.455 488.455"
      height="512px"
      viewBox="0 0 488.455 488.455"
      width="512px"
      css={css`
        fill: ${color || "#97d7d8"};
        max-width: 100%;
        height: ${height || "20"}px;
        width: ${width || "20"}px;
      `}
    >
      <g>
        <path
          d="m287.396 216.317c23.845 23.845 23.845 62.505 0 86.35s-62.505 23.845-86.35 0-23.845-62.505 0-86.35 62.505-23.845 86.35 0"
          data-original="#000000"
          className="active-path"
          data-old_color="#000000"
          fill={color || "#97d7d8"}
        />
        <path
          d="m427.397 91.581h-42.187l-30.544-61.059h-220.906l-30.515 61.089-42.127.075c-33.585.06-60.925 27.429-60.954 61.029l-.164 244.145c0 33.675 27.384 61.074 61.059 61.074h366.338c33.675 0 61.059-27.384 61.059-61.059v-244.236c-.001-33.674-27.385-61.058-61.059-61.058zm-183.177 290.029c-67.335 0-122.118-54.783-122.118-122.118s54.783-122.118 122.118-122.118 122.118 54.783 122.118 122.118-54.783 122.118-122.118 122.118z"
          data-original="#000000"
          className="active-path"
          data-old_color="#000000"
          fill={color || "#97d7d8"}
        />
      </g>{" "}
    </svg>
  );
};

export default CamraIcon;

CamraIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
