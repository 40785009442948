import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "@emotion/styled";
import { Form, Input, Select, DatePicker, Checkbox } from "antd";
import "antd/lib/form/style/css";
import "antd/lib/date-picker/style/css";
import "antd/lib/select/style/css";
import "antd/lib/checkbox/style/css";

import { FormWrapper, Loading } from "../index";
import { Colors } from "../../utils/index";

const { Option } = Select;
const FormItem = Form.Item;
const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const HaveAccount = styled.p`
  text-align: center;
  font-family: AvenirNext, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 30px auto 0;
  a {
    margin: 0;
  }
`;
class RegistrationForm extends React.Component {
  state = {
    loading: false,
  };
  componentDidUpdate(props) {
    // need to refactor and move loading to reduex
    const { loading } = this.state;
    if (loading && (props.errors || props.user)) {
      this.setState({ loading: false });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      register,
      form: { validateFields },
    } = this.props;
    this.setState({ loading: true });
    validateFields((err, values) => {

      this.setState({ loading: false });
      if (!err) {
        const {
          firstName,
          lastName,
          middleName,
          email,
          password,
          gender,
          dateOfBirth,
          allowed_to_contact
        } = values;
        const date = moment(dateOfBirth).unix("x");
        const user = {
          firstName,
          lastName,
          middleName,
          email,
          password,
          dateOfBirth: date,
          gender,
          allowed_to_contact:allowed_to_contact?true:false
        };
        register(user);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        {loading && <Loading />}
        <FormWrapper>
          <>
            <Form onSubmit={this.handleSubmit}>
              <legend>New Account</legend>
              <FormItem label={"First Name"}>
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "please fill First Name field",
                    },
                  ],
                })(<Input placeholder="First Name" />)}
              </FormItem>
              <FormItem label={"Middle Name"}>
                {getFieldDecorator(
                  "middleName",
                  {}
                )(<Input placeholder="Middle Name" />)}
              </FormItem>
              <FormItem label={"Last Name"}>
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "please fill Last Name field",
                    },
                  ],
                })(<Input placeholder="Last Name" />)}
              </FormItem>
              <FormItem label={"Email"}>
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      required: true,
                      message: "please fill Email field",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem label="Date Of birth">
                {getFieldDecorator("dateOfBirth", {
                  rules: [
                    {
                      type: "object",
                      required: true,
                      message: "Please select your Birth Date!",
                      value: moment("01/01/1990", "DD-MM-YYYY"),
                    },
                  ],
                })(
                  <DatePicker
                    format="DD-MM-YYYY"
                    placeholder="Date Of Birth!"
                  />
                )}
              </FormItem>
              <FormItem label="Gender">
                {getFieldDecorator("gender", {
                  rules: [
                    { required: true, message: "Please select your gender!" },
                  ],
                })(
                  <Select placeholder="Gender">
                    <Option value={"male"}>Male</Option>
                    <Option value={"female"}>Female</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem label="Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      message: "poor",
                      min: 6,
                    },
                  ],
                })(<Input.Password placeholder="Password" />)}
              </FormItem>
              <FormItem label="" className="registeation-checkbox">
                {getFieldDecorator("agreement",
                {
                  rules: [
                    {
                      required: true,
                      message: "You must agree with the terms and privacy policy!",
                    },
                  ],
                }
                )(
                  <Checkbox>
                    I agree to the <a href="https://staging.pat-rec.com/terms-of-use" target="_blank" className="terms-url">terms of use</a> and <a href="https://staging.pat-rec.com/privacy-policy" target="_blank" className="terms-url">privacy policy.</a>
                  </Checkbox>
                )}
              </FormItem>
              <FormItem label="" className="registeation-checkbox">
                {getFieldDecorator("allowed_to_contact")(
                  <Checkbox>
                    We'd love to keep you up-to-date. Please tick if you are
                    happy for us to contact you.
                  </Checkbox>
                )}
              </FormItem>
              <FormItem>
                <SubmitButton className="submit-button" htmlType="submit">
                  Register
                </SubmitButton>
              </FormItem>
              <HaveAccount>
                already have an account ? <Link to="/login">login!</Link>
              </HaveAccount>
            </Form>
          </>
        </FormWrapper>
      </>
    );
  }
}

export default Form.create({})(RegistrationForm);

RegistrationForm.propTypes = {
  register: PropTypes.func,
  signUp: PropTypes.func,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  user: PropTypes.object,
};
