/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const ReportIcon = ({ color, width, height }) => {
  return (
    <svg
      css={css`
        display: inline-block;
        fill: ${color || "#9298AC"};
        max-width: ${width || "24"}px;
        max-height: ${height || "25"}px;
        height: ${height || "25"}px;
        width: ${width || "24"}px;
      `}
      viewBox="0 0 24 25"
    >
      <path
        fill={color || "#9298AC"}
        d="M20.7304688,26 C21.4335938,26 22.0292969,25.7558594 22.5175781,25.2675781 C23.0058594,24.7792969 23.25,24.1835938 23.25,23.4804688 L23.25,6.01953125 C23.25,5.31640625 23.0058594,4.72070312 22.5175781,4.23242188 C22.0292969,3.74414062 21.4335938,3.5 20.7304688,3.5 L15.515625,3.5 C15.2421875,2.7578125 14.7832031,2.15234375 14.1386719,1.68359375 C13.4941406,1.21484375 12.78125,0.98046875 12,0.98046875 C11.21875,0.98046875 10.5058594,1.21484375 9.86132812,1.68359375 C9.21679688,2.15234375 8.7578125,2.7578125 8.484375,3.5 L3.26953125,3.5 C2.56640625,3.5 1.97070312,3.74414062 1.48242188,4.23242188 C0.994140625,4.72070312 0.75,5.31640625 0.75,6.01953125 L0.75,23.4804688 C0.75,24.1835938 0.994140625,24.7792969 1.48242188,25.2675781 C1.97070312,25.7558594 2.56640625,26 3.26953125,26 L20.7304688,26 Z M12,6.01953125 C11.6484375,6.01953125 11.3554688,5.89257812 11.1210938,5.63867188 C10.8867188,5.38476562 10.7695312,5.08203125 10.7695312,4.73046875 C10.7695312,4.37890625 10.8867188,4.0859375 11.1210938,3.8515625 C11.3554688,3.6171875 11.6484375,3.5 12,3.5 C12.3515625,3.5 12.6445312,3.6171875 12.8789062,3.8515625 C13.1132812,4.0859375 13.2304688,4.37890625 13.2304688,4.73046875 C13.2304688,5.08203125 13.1132812,5.38476562 12.8789062,5.63867188 C12.6445312,5.89257812 12.3515625,6.01953125 12,6.01953125 Z M18.2695312,11 L5.73046875,11 L5.73046875,8.48046875 L18.2695312,8.48046875 L18.2695312,11 Z M18.2695312,15.9804688 L5.73046875,15.9804688 L5.73046875,13.5195312 L18.2695312,13.5195312 L18.2695312,15.9804688 Z M14.5195312,21.0195312 L5.73046875,21.0195312 L5.73046875,18.5 L14.5195312,18.5 L14.5195312,21.0195312 Z"
        transform="translate(0 -1)"
      />
    </svg>
  );
};

export default ReportIcon;

ReportIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
