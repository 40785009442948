import React, { Component } from "react";
import styled from "@emotion/styled";
import moment from "moment";
import { Icon, Form, Input, Row, Col, Select, DatePicker } from "antd";
import PropTypes from "prop-types";
import "antd/lib/icon/style/css";
import "antd/lib/form/style/css";
import "antd/lib/upload/style/css";
import "antd/lib/row/style/css";
import "antd/lib/col/style/css";
import { UploadProfilePicture } from "../index";
// import { default as countries } from "./cuntry";
import ReactPhoneInput from "react-phone-input-2";

const FormItem = Form.Item;
const { Option } = Select;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #171a1b;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #97d7d8;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #97d7d8;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1.5;
    vertical-align: middle;
  }
`;

const UserInfoContentWrapper = styled.div`
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    text-align: center;
    margin: 0;
    * {
      max-width: 100%;
      max-height: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .ant-upload {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      padding: 0;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 50%;
        opacity: 0.7;
        box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.5),
          #97d7d8
        );
      }
    }
  }
`;

const UserInfoContent = styled.div`
  width: 100%;
  margin-top: 25px;
  & > .ant-row {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  input[type="tel"] {
    width: 100%;
  }
  .flag-dropdown {
    display: ${(props) => (props.editFormIsOpen ? `block` : "none")};
  }
  .country {
    line-height: 1;
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #171a1b;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  .ant-input-group-addon {
    width: 115px;
    padding: 0;
    .ant-select.ant-select-enabled {
      width: 100% !important;
    }
  }
  .countryIcons {
    width: 24px;
    height: 24px;
  }
`;

const MyInfoWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
`;

export default class MyInfoForm extends Component {
  state = {
    confirmDirty: false,
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const {
      user,
      form,
      form: { getFieldDecorator },
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
      consultant,
      setProfilePic,
      withOutDateAndGenter,
      bloodTypes,
    } = this.props;
    const userType = localStorage.getItem("userType");

    const editAccountForm = (
      <FormItem className="img-upload-item">
        <UploadProfilePicture
          form={form}
          user={user}
          setProfilePic={setProfilePic}
        />
      </FormItem>
    );

    return (
      <MyInfoWrapper>
        <UserInfoWrapper>
          <h2>My Info</h2>
          {handleEditFormToggle ? (
            editFormIsOpen ? (
              <div>
                <FormItem>
                  <button htmltype="submit" className="save">
                    Save
                  </button>
                </FormItem>
                <FormItem>
                  <button
                    htmltype="button"
                    className="close"
                    onClick={handleCancel}
                  >
                    &times;
                  </button>
                </FormItem>
              </div>
            ) : (
              <button
                className="ant-dropdown-link"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleEditFormToggle();
                }}
              >
                <Icon type="edit" theme="filled" />
              </button>
            )
          ) : (
            <></>
          )}
        </UserInfoWrapper>
        <UserInfoContentWrapper>
          {editFormIsOpen && editAccountForm}
          <UserInfoContent editFormIsOpen={editFormIsOpen}>
            <Row>
              {consultant && (
                <>
                  <Col span={12}>
                    <FormItem label={"First Name"}>
                      {getFieldDecorator("firstName", {
                        initialValue: user && user.name,
                        rules: [
                          {
                            required: true,
                            message: "Please input your First Name!",
                          },
                        ],
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label={"Last Name"}>
                      {getFieldDecorator("lastName", {
                        initialValue: user && user.name,
                        rules: [
                          {
                            required: true,
                            message: "Please input your Last Name!",
                          },
                        ],
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Password" hasFeedback>
                      {getFieldDecorator("password", {
                        rules: [
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                          {
                            validator: this.validateToNextPassword,
                          },
                        ],
                      })(<Input.Password />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Confirm Password" hasFeedback>
                      {getFieldDecorator("confirm", {
                        rules: [
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          {
                            validator: this.compareToFirstPassword,
                          },
                        ],
                      })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={12}>
                <FormItem label={"Email"}>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                      },
                      {
                        required: true,
                        message: "Please input your Email!",
                      },
                    ],
                    initialValue: user && user.email,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={"Phone Number"}>
                  {getFieldDecorator("mobile", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Mobile Number!",
                      },
                    ],
                    initialValue: user && user.mobile,
                  })(
                    <ReactPhoneInput
                      containerStyle={{ width: "100%", height: "42px" }}
                      searchClass="search-class"
                      // defaultCountry="gb"
                      placeholder="+44 1234 567890"
                      searchStyle={{
                        margin: "0",
                        width: "100%",
                        height: "30px",
                      }}
                      enableSearchField
                      disableSearchIcon
                      disabled={!editFormIsOpen && true}
                    />
                  )}
                </FormItem>
              </Col>
              {!withOutDateAndGenter && (
                <>
                  <Col span={12}>
                    <FormItem label="Gender">
                      {getFieldDecorator("gender", {
                        initialValue: user && user.gender,
                        rules: [
                          {
                            required: true,
                            message: "Please input your Gender!",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Gender"
                          disabled={!editFormIsOpen && true}
                        >
                          <Option value="male">Male</Option>
                          <Option value="female">Female</Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label="Date of Birth">
                      {getFieldDecorator("dateOfBirth", {
                        initialValue:
                          user &&
                          user.dateOfBirth &&
                          moment.unix(user.dateOfBirth),
                        rules: [
                          {
                            required: true,
                            message: "Please input your Birth Date!",
                          },
                        ],
                      })(
                        <DatePicker
                          format="Do MMM YY"
                          disabled={!editFormIsOpen && true}
                        />
                      )}
                    </FormItem>
                  </Col>
                </>
              )}
              {userType === "patient" &&
                user &&
                (user.bloodType || editFormIsOpen) && (
                  <Col span={12}>
                    <FormItem label="Blood Type">
                      {getFieldDecorator("bloodType", {
                        initialValue: user && user.bloodTypesId,
                      })(
                        <Select
                          placeholder="Blood Type"
                          disabled={!editFormIsOpen && true}
                        >
                          {bloodTypes &&
                            bloodTypes.length &&
                            bloodTypes.map((type) => {
                              return (
                                <Option key={type.id} value={type.id}>
                                  {type.name}
                                </Option>
                              );
                            })}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                )}
            </Row>
          </UserInfoContent>
        </UserInfoContentWrapper>
      </MyInfoWrapper>
    );
  }
}

MyInfoForm.propTypes = {
  user: PropTypes.object,
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
  consultant: PropTypes.bool.isRequired,
  setProfilePic: PropTypes.func,
  withOutDateAndGenter: PropTypes.bool,
};
