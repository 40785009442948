import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const FullPgaeWrapper = styled.div`
  min-height: calc(100vh - 120px);
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
`;

export default function FullPgae(props) {
  return <FullPgaeWrapper>{props.children}</FullPgaeWrapper>;
}

FullPgae.propTypes = {
  children: PropTypes.element,
};
