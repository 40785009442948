import React, { Component } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import moment from "moment";

import { H1, ProfilePic } from "../../utils/index";

const PatientInfoWrapper = styled.div`
  margin-bottom: 15px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 10px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }
`;

const PatientInfoHeader = styled.header`
  background-color: #f9fafc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 6px 6px 0 0;
`;

const PatientInfoBody = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 0 0 6px 6px;
  .patientInfo-body__content {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-family: Heebo;
      font-size: 12px;
      font-weight: 500;
      color: #9298ac;
      margin-bottom: 5px;
      & + p {
        font-family: Heebo;
        font-size: 14px;
        font-weight: 500;
        color: #060c2b;
      }
    }
  }
`;

export default class PatientInfo extends Component {
  render() {
    const { patient, noImg, viewEligibility, eligibility } = this.props;
    const BirthDay = patient.dateOfBirth || patient.date_of_birth;

    return (
      <PatientInfoWrapper>
        <PatientInfoHeader>
          <H1>Patient Info</H1>
        </PatientInfoHeader>
        <PatientInfoBody>
          {!noImg && (
            <ImageWrapper>
              <ProfilePic user={patient} />
              <p>{patient.name || patient.fullName}</p>
            </ImageWrapper>
          )}
          <div className="patientInfo-body__content">
            <p>Gender</p>
            {patient && patient.gender ? (
              <p>
                {patient.gender.charAt(0).toUpperCase() +
                  patient.gender.slice(1)}
              </p>
            ) : (
              <p>Male</p>
            )}
          </div>
          {BirthDay && (
            <div className="patientInfo-body__content">
              <p>Date of Birth</p>
              <p>{moment.unix(BirthDay).format("MMM DD, YYYY")}</p>
            </div>
          )}

          {viewEligibility && (
            <div className="patientInfo-body__content">
              <p>Eligibility to enter uk?</p>
              <p>{eligibility ? "Yes" : "No"}</p>
            </div>
          )}
        </PatientInfoBody>
      </PatientInfoWrapper>
    );
  }
}

PatientInfo.propTypes = {
  patient: PropTypes.object.isRequired,
  noImg: PropTypes.bool,
  viewEligibility: PropTypes.bool,
  eligibility: PropTypes.bool,
};
