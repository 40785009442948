/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const ConditionsIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29 35"
      css={css`
        fill: ${color || "#97d7d8"};
        max-width: 100%;
        height: ${height || "29"}px;
        width: ${width || "35"}px;
      `}
    >
      <path
        fillRule="evenodd"
        d="M23.44.37c.666 0 1.246.372 1.543.92 1.541.178 2.891 1.06 3.683 2.434.892 1.546.892 3.391 0 4.937l-5.588 9.69c-.743 1.29-1.977 2.143-3.4 2.392v4.673c0 5.526-3.472 10.022-7.74 10.022-4.267 0-7.74-4.496-7.74-10.022v-5.565c-1.98-.404-3.476-2.163-3.476-4.265 0-2.4 1.95-4.352 4.347-4.352 2.397 0 4.347 1.952 4.347 4.352 0 2.102-1.496 3.861-3.477 4.265v5.565c0 4.566 2.691 8.28 6 8.28 3.308 0 6-3.714 6-8.28v-4.673c-1.423-.25-2.657-1.103-3.4-2.391L8.951 8.66c-.891-1.546-.891-3.391 0-4.937.787-1.365 2.124-2.244 3.653-2.43.295-.55.875-.924 1.543-.924.969 0 1.754.787 1.754 1.757 0 .97-.785 1.756-1.754 1.756-.632 0-1.186-.334-1.494-.836-.919.162-1.715.715-2.195 1.548-.577 1-.577 2.195 0 3.195l5.588 9.69c.576 1.001 1.61 1.598 2.763 1.598 1.153 0 2.186-.597 2.763-1.597L27.16 7.79c.577-1 .577-2.195 0-3.195-.485-.841-1.293-1.397-2.223-1.553-.31.505-.864.841-1.497.841-.968 0-1.754-.786-1.754-1.756S22.472.37 23.44.37z"
      />
    </svg>
  );
};

export default ConditionsIcon;

ConditionsIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
