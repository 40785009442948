import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import { PrimaryButton, H1, device } from "../../utils/index";
import { AcceptMessage, AcceptMessage2X } from "../../images/index";

const AcceptConsultantsWrapper = styled.div`
  text-align: center;
  padding: 20px;
  min-width: 450px;
  @media ${device.allMobile} {
    min-width: 80%;
    max-width: 100%;
  }
  .primary-button {
    width: 100%;
    a {
      font-size: 15px;
      font-weight: 500;
      padding: 11px;
    }
  }
`;
const AcceptConsultantsImage = styled.div`
  margin: 10px auto;
  img {
    width: 216px;
    height: 120px;
  }
`;
const AcceptConsultantsContent = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
  }
  .content {
    font-size: 16.8px;
    font-weight: normal;
    max-width: 320px;
    margin: 10px auto;
  }
  .time {
    font-size: 18px;
    font-weight: bold;
  }
`;
export default function AcceptConsultants(props) {
  return (
    <AcceptConsultantsWrapper>
      <AcceptConsultantsImage>
        <picture>
          <source srcSet={`${AcceptMessage2X} 2x`} />
          <img src={AcceptMessage} alt="Thanks" />
        </picture>
      </AcceptConsultantsImage>
      <AcceptConsultantsContent>
        <H1>Accept succefuly</H1>
        <p className="content">
          thank you for accept the request , your time to respond start from now
        </p>
        <p className="time">06:00 </p>
      </AcceptConsultantsContent>
      <PrimaryButton className="primary-button">
        <Link to={`/consultant/consultation/${props.consultantsId}`}>
          Add Report
        </Link>
      </PrimaryButton>
    </AcceptConsultantsWrapper>
  );
}

AcceptConsultants.propTypes = {
  consultantsId: PropTypes.number.isRequired,
};
