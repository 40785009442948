import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Colors } from "./Theme";

const Button = styled.div`
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : "300px")};
  display: inline-block;
  a,
  button {
    text-align: center;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.secondaryColor};
    border: 1px solid ${Colors.secondaryColor};
    padding: 15px;
    border-radius: 7.2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border: solid 0.6px ${Colors.secondaryColor};
    background-color: ${Colors.white};
    min-width: 100%;
    display: inline-block;
    &:hover {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
`;

export default function ButtonWrapper(props) {
  return <Button {...props}>{props.children}</Button>;
}

ButtonWrapper.propTypes = {
  children: PropTypes.object,
  minWidth: PropTypes.string,
};
