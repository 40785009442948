import React, { Component } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import PropTypes from "prop-types";
import { FaceBookIcon, FaceBookIcon2X } from "../../images/index";
import styled from "@emotion/styled";

const FacebookButton = styled.button`
  width: 100%;
  background: #454585;
  color: #fff;
  padding: 10px;
  border-radius: 6px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  border: none;
  box-shadow: none;
  position: relative;
  text-align: center;
  i {
    margin-right: 10px;
  }
`;

const FacebookIcon = styled.picture`
  margin-right: 10px;
  img {
    width: 22px;
    height: 22px;
    display: inline-block;
  }
`;

export default class Facebook extends Component {
  state = {
    isLoggedIn: false,
    userId: "",
    name: "",
    email: "",
    accessToken: "",
  };
  responseFacebook = response => {
    const { logIn } = this.props;
    logIn(response.accessToken);
  };
  render() {
    let fbContent;

    const { isLoggedIn } = this.state;
    const { facebookId } = this.props;
    if (isLoggedIn) {
      fbContent = null;
    } else {
      fbContent = (
        <FacebookLogin
          appId={facebookId}
          autoLoad={false}
          callback={this.responseFacebook}
          fields="name,email,picture,gender"
          render={renderProps => (
            <FacebookButton onClick={renderProps.onClick}>
              <FacebookIcon>
                <source srcSet={`${FaceBookIcon2X} 2x`} />
                <img src={FaceBookIcon} alt="Holoden Khight Logo" />
              </FacebookIcon>
              Login with Facebook
            </FacebookButton>
          )}
        />
      );
    }

    return <div>{fbContent}</div>;
  }
}

Facebook.propTypes = {
  logIn: PropTypes.func.isRequired,
  facebookId: PropTypes.string.isRequired,
};
