import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Icon } from "antd";
import { Link } from "react-router-dom";
import { Colors } from "../utils/index";

const SeeMoreWrapper = styled.div`
  display: inline-block;
  margin: auto;
  a {
    color: ${Colors.black};
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    span {
      margin-left: 7px;
      position: relative;
      top: 1px;
    }
  }
`;

export default function SeeMoreCompoent(props) {
  return (
    <SeeMoreWrapper>
      <Link to={props.to}>
        More
        <span>
          <Icon type="arrow-right" />
        </span>
      </Link>
    </SeeMoreWrapper>
  );
}
SeeMoreCompoent.propTypes = {
  to: PropTypes.string.isRequired,
};
