/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

const BookedQuotations = ({ color, width, height }) => {
  return (
    <svg
      css={css`
        fill: ${color || "#9298AC"};
        max-width: ${width || "28"}px;
        max-height: ${height || "29"}px;
        height: ${height || "29"}px;
        width: ${width || "28"}px;
      `}
      viewBox="0 0 28 29"
    >
      <path
        fill={color || "#9298AC"}
        d="M25.25,23.4804688 C25.953125,23.4804688 26.5488281,23.2363281 27.0371094,22.7480469 C27.5253906,22.2597656 27.7695312,21.6835938 27.7695312,21.0195312 L27.7695312,21.0195312 L27.7695312,6.01953125 C27.7695312,5.31640625 27.5253906,4.72070312 27.0371094,4.23242188 C26.5488281,3.74414062 25.953125,3.5 25.25,3.5 L25.25,3.5 L24.0195312,3.5 L24.0195312,0.98046875 L21.5,0.98046875 L21.5,3.5 L11.4804688,3.5 L11.4804688,0.98046875 L9.01953125,0.98046875 L9.01953125,3.5 L7.73046875,3.5 C7.06640625,3.5 6.49023438,3.74414062 6.00195312,4.23242188 C5.51367188,4.72070312 5.26953125,5.31640625 5.26953125,6.01953125 L5.26953125,6.01953125 L5.26953125,21.0195312 C5.26953125,21.6835938 5.51367188,22.2597656 6.00195312,22.7480469 C6.49023438,23.2363281 7.06640625,23.4804688 7.73046875,23.4804688 L7.73046875,23.4804688 L25.25,23.4804688 Z M25.25,21.0195312 L7.73046875,21.0195312 L7.73046875,9.76953125 L25.25,9.76953125 L25.25,21.0195312 Z M20.2695312,28.5195312 L20.2695312,26 L2.75,26 L2.75,11 L0.23046875,11 L0.23046875,26 C0.23046875,26.703125 0.474609375,27.2988281 0.962890625,27.7871094 C1.45117188,28.2753906 2.046875,28.5195312 2.75,28.5195312 L2.75,28.5195312 L20.2695312,28.5195312 Z M15.3476562,19.1445312 L20.9140625,13.578125 L19.5664062,12.2304688 L15.3476562,16.4492188 L13.3554688,14.4570312 L12.0078125,15.7460938 L15.3476562,19.1445312 Z"
      />
    </svg>
  );
};

export default BookedQuotations;

BookedQuotations.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
