import React, { Component } from "react";
import { Row, Col, Card, Icon } from "antd";
import styled from "@emotion/styled";
import "antd/lib/card/style/css";
import { Link } from "react-router-dom";
import {
  H1,
  convertDateToFullStringDate,
  Breadcrumb,
  Modal,
} from "../../utils/index";
import { pdfFile } from "../../images/index";
import { PatientInfo, Readings, RequestMedicalRecordForm } from "../index";

const { Meta } = Card;
const BackToConsolation = styled.div`
  margin-bottom: 30px;
  a {
    color: #171a1b;
    font-size: 18px;
    span {
      margin-right: 10px;
    }
  }
`;
const MedicalReportWrapper = styled.div`
  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .ant-col {
    padding: 0 10px;
  }
  .ant-card {
    display: inline-block;
    margin-bottom: 15px;
    img {
      max-width: 240px;
      max-height: 180px;
      ${({ isPDF }) => isPDF && `object-fit: none;`}
    }
    .ant-card-body {
      padding: 15px;
    }
    .ant-card-meta-title {
      font-size: 13px;
      margin-bottom: 5px;
      line-height: 1;
      color: #060c2b;
    }
    .ant-card-meta-description {
      font-size: 12px;
      color: #9298ac;
    }
  }
`;
const DescriptionWrapper = styled.div`
  background: #fff;
  padding: 20px;
  .time {
    font-size: 15px;
    color: #9298ac;
    margin-bottom: 7px;
  }
  .description {
    p {
      font-size: 16px;
      color: #060c2b;
      line-height: 2;
      margin: 0;
    }
  }
`;
const ImageWrapper = styled.div`
  img {
    max-height: 750px;
  }
`;
class MedicalReport extends Component {
  state = {
    showImageModal: false,
    showRequestModal: false,
    modalTitle: "",
    modalImage: "",
  };

  handelShowImageModal = (title) => {
    this.setState({
      showImageModal: true,
      modalTitle: title,
    });
  };

  handelShowRequestModal = (title, image, scanType) => {
    this.setState({
      showRequestModal: true,
      modalTitle: title,
      modalImage: image,
      scanType,
    });
  };

  handelCloseModal = () => {
    this.setState({ showImageModal: false, showRequestModal: false });
  };

  render() {
    const {
      showImageModal,
      modalTitle,
      modalImage,
      scanType,
      showRequestModal,
    } = this.state;

    const {
      title,
      description,
      image,
      BreadcrumbLinks,
      updatedAt,
      consultationId,
      consultation,
      requestScan,
    } = this.props;
    const notes = description.split(/\n/g);
    const userType = localStorage.getItem("userType");
    const isPDF = image?.fileName?.includes("pdf");

    return (
      <MedicalReportWrapper isPDF={isPDF}>
        <Row>
          <Col span={16}>
            {userType === "consultant" && (
              <BackToConsolation>
                <Link to={`/consultation/${consultationId}`}>
                  <span>
                    <Icon type="arrow-left" />
                  </span>
                  Back to Consultation
                </Link>
              </BackToConsolation>
            )}
            <Breadcrumb className="bread-crumb" links={BreadcrumbLinks} />
            <H1>{title}</H1>
            {image && image.fileLocation && (
              <div>
                <Card
                  hoverable
                  style={{ width: 220 }}
                  cover={
                    <img
                      alt={image.fileName}
                      src={isPDF ? pdfFile : image.fileLocation}
                    />
                  }
                  onClick={() => {
                    if (!isPDF) {
                      this.handelShowImageModal(
                        image.fileName
                          .replace(".jpg", "")
                          .replace(".jpeg", "")
                          .replace(".png", "")
                      );
                    } else {
                      window.open(image.fileLocation, "_blank").focus();
                    }
                  }}
                >
                  <Meta
                    title={image.fileName
                      .replace(".jpg", "")
                      .replace(".jpeg", "")
                      .replace(".png", "")}
                    description={convertDateToFullStringDate(updatedAt)}
                  />
                </Card>
              </div>
            )}
            <DescriptionWrapper>
              <p className="time">{convertDateToFullStringDate(updatedAt)}</p>
              <div className="description">
                {notes.length > 0 &&
                  notes.map((note, index) => (
                    <p key={`${note}-${index}`}>{note}</p>
                  ))}
              </div>
            </DescriptionWrapper>
          </Col>
          {userType === "consultant" && consultation && (
            <Col span={8}>
              <PatientInfo patient={consultation.patient} />
              <Readings
                readings={consultation.readings}
                requestScan={requestScan}
                consultationId={consultationId}
                handelShowModal={this.handelShowRequestModal}
              />
            </Col>
          )}
        </Row>
        <Modal
          title={modalTitle}
          showModal={showImageModal}
          handelCloseModal={this.handelCloseModal}
        >
          <ImageWrapper>
            {image && image.fileLocation && (
              <img alt={image.fileName} src={image.fileLocation} />
            )}
          </ImageWrapper>
        </Modal>
        <Modal
          title={modalTitle}
          image={modalImage}
          showModal={showRequestModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={requestScan}
              scanType={scanType}
              consultationId={consultation && consultation.id}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
      </MedicalReportWrapper>
    );
  }
}

export default MedicalReport;
