import React, { Component } from "react";
import styled from "@emotion/styled";
import { Form, Input, Select } from "antd";
import "antd/lib/form/style/css";
import PropTypes from "prop-types";

import { FormWrapper } from "../index";
import { Colors } from "../../utils/index";

const FormItem = Form.Item;
const Option = Select.Option;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const RequestMedicalRecordFormWrapper = styled.div`
  .text-area {
    margin-top: 15px;
    height: 150px;
    margin-bottom: 15px;
  }
  .Form-wrapper {
    border: none;
  }
  .ant-select {
    margin-bottom: 15px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

class RequestMedicalRecordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      requestScan,
      consultationId,
      scanType,
      handelCloseModal,
      patientId,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        requestScan({
          ...values,
          patient_id: patientId ? patientId : null,
          scanType: scanType
            ? scanType === "reading"
              ? values.scanType
              : scanType
            : values.scanType,
          scan_type: scanType ? scanType : values.scanType,
          id: consultationId ? consultationId : null,
          title: values.scanName,
          body: values.notes,
        });
        handelCloseModal();
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
      showTypes,
      showPrice,
      scanType,
    } = this.props;

    const scanTypes = [
      { value: "radiology-ct", title: "CT" },
      { value: "radiology-xray", title: "X-ray" },
      { value: "radiology-mri", title: "MRI" },
      { value: "ecg", title: "ECG" },
      { value: "medical_report", title: "Medical report" },
      { value: "allergy", title: "Allergy" },
      { value: "condition", title: "Condition" },
      { value: "lab_result", title: "Label result" },
      { value: "reading-blood-pressure", title: "Blood pressure" },
      { value: "reading-blood-sugar", title: "Blood sugar" },
      { value: "reading-weight", title: "Weight" },
      { value: "reading-height", title: "Height" },
      { value: "reading-heart-rate", title: "Heart rate" },
      { value: "reading-temperature", title: "Temperature" },
    ];
    const readingsTypes = [
      { value: "reading-blood-pressure", title: "Blood pressure" },
      { value: "reading-blood-sugar", title: "Blood sugar" },
      { value: "reading-weight", title: "Weight" },
      { value: "reading-height", title: "Height" },
      { value: "reading-heart-rate", title: "Heart rate" },
      { value: "reading-temperature", title: "Temperature" },
    ];
    return (
      <RequestMedicalRecordFormWrapper>
        <FormWrapper>
          <Form onSubmit={this.handleSubmit}>
            {scanType === "reading" && (
              <FormItem>
                {getFieldDecorator("scanType", {
                  rules: [
                    {
                      required: true,
                      message: "please fill Scan Name field",
                    },
                  ],
                })(
                  <Select placeholder="Scan Type">
                    {readingsTypes &&
                      readingsTypes.map((type) => (
                        <Option
                          key={`readingsTypes-${type.value}`}
                          value={type.value}
                        >
                          {type.title}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
            {showTypes && (
              <FormItem>
                {getFieldDecorator("scanType", {
                  rules: [
                    {
                      required: true,
                      message: "please fill Scan Name field",
                    },
                  ],
                })(
                  <Select placeholder="Scan Type">
                    {scanTypes &&
                      scanTypes.map((type) => (
                        <Option
                          key={`scanTypes-${type.value}`}
                          value={type.value}
                        >
                          {type.title}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem>
              {getFieldDecorator(
                "scanName",
                {}
              )(<Input placeholder="Add request title" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator(
                "notes",
                {}
              )(<Input.TextArea placeholder="Notes" className="text-area" />)}
            </FormItem>
            {showPrice && (
              <FormItem>
                {getFieldDecorator("price", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Scan Price!",
                    },
                  ],
                })(<Input type="number" placeholder="Scan Price" />)}
              </FormItem>
            )}
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Request
              </SubmitButton>
            </FormItem>
          </Form>
        </FormWrapper>
      </RequestMedicalRecordFormWrapper>
    );
  }
}

export default Form.create({})(RequestMedicalRecordForm);
RequestMedicalRecordForm.propTypes = {
  form: PropTypes.object.isRequired,
  requestScan: PropTypes.func.isRequired,
  consultationId: PropTypes.number,
  scanType: PropTypes.string,
  handelCloseModal: PropTypes.func.isRequired,
  showTypes: PropTypes.bool,
  showPrice: PropTypes.bool,
  patientId: PropTypes.number,
};
